import React from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link as GatsbyLink } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
}));

function NotFoundPage() {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Section heading="Not Found">
        <Typography component="p" variant="body1">
          Not all who wander are lost, but just in case you are...
        </Typography>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          size="large"
          component={GatsbyLink}
          to="/"
        >
          Go Home
        </Button>
      </Section>
    </Layout>
  );
}

export default NotFoundPage;
